exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anketa-js": () => import("./../../../src/pages/anketa.js" /* webpackChunkName: "component---src-pages-anketa-js" */),
  "component---src-pages-cenovnik-js": () => import("./../../../src/pages/cenovnik.js" /* webpackChunkName: "component---src-pages-cenovnik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalkulator-js": () => import("./../../../src/pages/kalkulator.js" /* webpackChunkName: "component---src-pages-kalkulator-js" */),
  "component---src-pages-klijenti-[id]-js": () => import("./../../../src/pages/klijenti/[id].js" /* webpackChunkName: "component---src-pages-klijenti-[id]-js" */),
  "component---src-pages-klijenti-js": () => import("./../../../src/pages/klijenti.js" /* webpackChunkName: "component---src-pages-klijenti-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nutrimeal-js": () => import("./../../../src/pages/nutrimeal.js" /* webpackChunkName: "component---src-pages-nutrimeal-js" */),
  "component---src-pages-pitajte-nutricionistu-js": () => import("./../../../src/pages/pitajte-nutricionistu.js" /* webpackChunkName: "component---src-pages-pitajte-nutricionistu-js" */),
  "component---src-pages-recepti-js": () => import("./../../../src/pages/recepti.js" /* webpackChunkName: "component---src-pages-recepti-js" */),
  "component---src-pages-sonja-js": () => import("./../../../src/pages/sonja.js" /* webpackChunkName: "component---src-pages-sonja-js" */)
}

